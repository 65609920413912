<template>
    <section id="services" class="features-area" style="text-align: justify;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="section-title text-center pb-20">
                        <h3>{{ $t('Contents.OurServices') }}</h3>
                        <div class="c-line"></div>
                        <!-- <p class="text">Stop wasting time and money designing and managing a website that doesn’t get results. Happiness guaranteed!</p> -->
                    </div>
                    <!-- row -->
                </div>
            </div>
            <!-- row -->
            <div class="row justify-content-center">

                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-featuress mt-10" style="padding-bottom: 26px;">
                        <v-lazy-image src="assets/images/service/low_voltage.webp"  alt="low_voltage"/>
                        <div class="body-service single-features mt-10" >
                        
                                <div class="features-title-icon  justify-content-between">
                                <h4 class="features-title"><a href="#" style="font-size:25px;padding-bottom: 15px">{{ $t('Contents.OurServicesDetails.LowVoltage') }}</a></h4>
                                
                            </div>
                            <div class="features-content">
                                <ul class="c-font-uppercase">
                                    <li> {{ $t('Contents.OurServicesDetails.LowVoltageDetails.DistributionControlPanels') }}  </li>
                                    <li> {{ $t('Contents.OurServicesDetails.LowVoltageDetails.LightingWD') }} </li>
                                    <!-- <li> {{ $t('Contents.OurServicesDetails.LowVoltageDetails.LowCurrent') }} </li> -->
                                    <!-- <li> <i class="lni lni-bolt"></i> {{ $t('Contents.OurServicesDetails.LowVoltageDetails.CableTermination') }}. </li> -->
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-featuress mt-10">
                        <v-lazy-image src="assets/images/service/energy.webp"  alt="energy"/>
                        <div class="body-service single-features mt-10">
                        
                                <div class="features-title-icon  justify-content-between">
                                    <h4 class="features-title"><a href="#" style="font-size:25px;padding-bottom: 15px">{{ $t('Contents.OurServicesDetails.Energy') }}</a></h4>
                                </div>
                            <div class="features-content">
                                <ul class="c-font-uppercase">
                                    <li> {{ $t('Contents.OurServicesDetails.EnergyDetails.UPSAVR') }} </li>
                                    <li> {{ $t('Contents.OurServicesDetails.EnergyDetails.Automation') }} </li>
                                    <li> {{ $t('Contents.OurServicesDetails.EnergyDetails.Genset') }} </li>
                                    <li> {{ $t('Contents.OurServicesDetails.EnergyDetails.Solar') }} </li>
                                </ul>
                            </div>
                            
                        </div>
                    
                    </div>
                </div>
           
                 
                 <div class="col-lg-4 col-md-7 col-sm-9">
                    <div class="single-featuress mt-10">
                      <v-lazy-image src="assets/images/service/medium_voltage.webp"  alt="medium_voltage"/>
                        <div class="body-service single-features mt-10">
                         
                                <div class="features-title-icon  justify-content-between">
                                <h4 class="features-title"><a href="#" style="font-size:21px;padding-bottom: 15px">{{ $t('Contents.OurServicesDetails.MediumVoltage') }}</a></h4>
                                
                            </div>
                            <div class="features-content">
                                <ul class="c-font-uppercase">
                                    <li>   {{ $t('Contents.OurServicesDetails.MediumVoltageDetails.Substation') }}  </li>
                                    <li>   {{ $t('Contents.OurServicesDetails.MediumVoltageDetails.Gensets') }}  </li>
                                    <li>   {{ $t('Contents.OurServicesDetails.MediumVoltageDetails.Switchgears') }}  </li>
                                    <li style="font-size: 18px;"> {{ $t('Contents.OurServicesDetails.MediumVoltageDetails.CableTermination') }}  </li>
                                </ul>
                            </div>
                            
                        </div>
                    
                    </div>
                </div>

               
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";

export default {
  components: {
    VLazyImage
  }
};
</script>

<style>
    .list-circle{
        list-style: circle !important;
    }

    .features-area {
        position: relative;
        background-color: none;
        padding-top: 60px !important;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .features-area::before {
        height: 100vh;
        content: '';
        position: fixed;
        z-index: -1;
        display: block;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        background-image: url("/assets/images/bg-min.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .service-image{
        padding: 0px 18px 52px;
    }

    .single-featuress{
        /* padding: 0px 18px 52px !important; */
        background-color: #fff;
        
    }

    .single-featuress img{
        min-height: 260px !important;
    }

    @media(max-width :1000px) {
        .single-featuress img{
            min-height: 140px !important;
        }
    };

    .body-service{

    }


    .single-featuress:hover {
    
        position: absolute;
        top: 0;
        left: 0;
        /* color: #208e87; */
        z-index: 1;
    }

    .c-line{
        width: 30px;
        height: 3px;
        background-color: #208e87;
        margin: 30px auto 30px auto;

    }

    i{
            color: #208e87;

    }

    .c-font-uppercase {
        
        /* text-transform: uppercase; */
    }

    .single-featuress{
        text-align: center;
    }
</style>